import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import DarkMessage from './dark-message';
import XCloseSvg from '../assets/icons0/x-close.svg';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';

const SUPPORT_TELEGRAM = 'https://t.me/Ottry_Support';

const checkCartEntry = ({entry, endpoints, serviceId}) => {
    const endpoint = endpoints.find(item => item.id === entry.endpointId);
    if (!endpoint) return [null, {...entry, unavailable: true}];
    // isn't bind to current service
    if (!endpoint.services.some(s => s.id === serviceId)) return [null, {...entry, unavailable: true}];
    // is expired
    if (endpoint.end && moment(endpoint.end).diff(moment()) < 0) return [null, {...entry, unavailable: true}];
    // is sold out already
    if (endpoint.quantity === 0) return [null, {...entry, unavailable: true}];
    // is requested quantity available
    if (entry.quantity <= endpoint.quantity) return [{...entry}];
    // is less than requested quantity available
    if (entry.quantity > endpoint.quantity) return [{...entry, quantity: endpoint.quantity}, {...entry, quantity: entry.quantity - endpoint.quantity, unavailable: true}];
}

const CartCheck = ({cartState, serviceId, cartDispatch, update, children}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {ids: [...cartState.map(e => e.endpointId)], fetchKey: 0}
    });
    const [showWarning, setShowWarning] = React.useState(true);
    const [cartChecked, setCartChecked] = React.useState([]);
    const [hasUnavailableEndpoints, setHasUnavailableEndpoints] = React.useState(false);
    const {endpointsByIds} = useLazyLoadQuery(
        graphql`
            query cartCheckEndpointsByIdsQuery($ids: [String]) {
                endpointsByIds(ids: $ids) {
                    id
                    quantity
                    end
                    services {
                        id
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    React.useEffect(() => {
        if (update) {
            setShowWarning(false);
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    ids: prev.variables.ids,
                    fetchKey: prev.variables.fetchKey + 1
                }
            }))
        }
    }, [update]);
    React.useEffect(() => {
        const availableEndpoints = [];
        const unavailableEndpoints = [];
        cartState.forEach((entry) => {
            const [available, unavailable] = checkCartEntry({entry, endpoints: endpointsByIds, serviceId});
            if (available) availableEndpoints.push(available);
            if (unavailable) unavailableEndpoints.push(unavailable);
        });
        setCartChecked([...availableEndpoints, ...unavailableEndpoints]);
        setHasUnavailableEndpoints(!!unavailableEndpoints.length);
    }, [endpointsByIds, cartState]);
    

    const removeUnavailableEndpoints = () => {
        const unavailable = cartChecked.filter(entry => entry.unavailable);
        unavailable.forEach(({endpointId, serviceId, quantity}) => cartDispatch({type: 'substract', payload: {endpointId, serviceId, quantity}}));
    }
    return (
        <>
            {children({
                cartChecked,
                hasUnavailableEndpoints
            })}
            
            {hasUnavailableEndpoints && showWarning &&
                <div className='position-fixed top-1rem padding-right-1rem padding-left-1rem width-100percent right-0 max-width-23dot5rem'>
                    <DarkMessage {...{title: <FormattedMessage defaultMessage='The order contains unavailable products'/>, message: <FormattedMessage defaultMessage='To complete your order, remove items that are currently unavailable for purchase'/>, style: {boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)'}}}>
                        <div className='display-flex'>
                            <a className='tex-sm medium color-gray-100 cursor-pointer' href={SUPPORT_TELEGRAM} target='_blank'><FormattedMessage defaultMessage='Support'/></a>
                            <div className='tex-sm medium color-gray-400 padding-left-0dot5rem padding-right-0dot5rem'>·</div>
                            <div className='tex-sm medium color-orange-500 cursor-pointer display-flex' onClick={removeUnavailableEndpoints}>
                                <XCloseSvg className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                                <FormattedMessage defaultMessage='Remove unavailable'/>
                            </div>
                        </div>
                    </DarkMessage>
                </div>
            }
            {hasUnavailableEndpoints && update &&
                <div className='position-fixed top-1rem padding-right-1rem padding-left-1rem width-100percent max-width-23dot5rem right-0 z-index-1'>
                    <div className='border-1px-solid border-color-error-300 border-radius-0dot75rem padding-1rem background-color-white'>
                        <div className='display-flex text-xs medium color-error-700 padding-top-0dot25rem padding-left-0dot25rem padding-right-0dot625rem padding-bottom-0dot25rem background-color-error-50 border-radius-1rem align-items-center width-fit-content margin-bottom-0dot75rem'>
                            <div className='background-color-white border-radius-1rem padding-left-0dot5rem padding-right-0dot5rem padding-top-0dot125rem padding-bottom-0dot125rem'><FormattedMessage defaultMessage='Error'/></div>
                            <div className='padding-left-0dot5rem'><FormattedMessage defaultMessage='The order contains unavailable products'/></div>
                        </div>
                        <div className='text-sm color-error-600 padding-bottom-0dot25rem'><FormattedMessage defaultMessage='To complete your order, remove items that are currently unavailable for purchase'/></div>
                        <div className='padding-top-0dot5rem display-flex'>
                            <a className='tex-sm medium color-error-700 cursor-pointer' href={SUPPORT_TELEGRAM} target='_blank'><FormattedMessage defaultMessage='Support'/></a>
                            <div className='tex-sm medium color-error-400 padding-left-0dot5rem padding-right-0dot5rem'>·</div>
                            <div className='tex-sm medium color-error-700 cursor-pointer display-flex' onClick={removeUnavailableEndpoints}>
                                <XCloseSvg className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                                <FormattedMessage defaultMessage='Remove unavailable'/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default React.memo(CartCheck);