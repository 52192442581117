import React from 'react';
import ArrowNarrowRight from '../assets/icons0/arrow-narrow-right.svg';
import {useClaimDispatch} from './claim';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../utilities/search-filter';
import DarkMessage from './dark-message';
import {FormattedMessage} from 'react-intl';

const ImpulseUncompleted = ({impulseId, impulseExpired}) => {
    const claimDispatch = useClaimDispatch();
    const location = useLocation();
    return (
        <DarkMessage {...{title: <FormattedMessage defaultMessage='You have an unpaid order'/>, message: <FormattedMessage defaultMessage='Complete your order or cancel it before starting a new one.'/>, style: {boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.04), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 0px 1px 0px rgba(16, 24, 40, 0.25)'}}}>
            <div className='display-flex'>
                <div className='tex-sm medium color-gray-50 cursor-pointer' onClick={() => claimDispatch({type: 'remove', payload: {id: impulseId}})}><FormattedMessage defaultMessage='Cancel'/></div>
                <div className='tex-sm medium color-error-25 padding-left-0dot5rem padding-right-0dot5rem'>·</div>
                {impulseExpired
                    ?
                    <div className='tex-sm medium color-gray-600 display-flex'>
                        <FormattedMessage defaultMessage='Checkout'/>
                        <ArrowNarrowRight className='display-block height-1dot25rem width-1dot25rem margin-left-0dot5rem'/>
                    </div>
                    :
                    <Link to={`/confirm${searchFilter(location.search, {impulseId})}`}>
                        <div className='tex-sm medium color-orange-500 cursor-pointer display-flex'>
                            <FormattedMessage defaultMessage='Checkout'/>
                            <ArrowNarrowRight className='display-block height-1dot25rem width-1dot25rem margin-left-0dot5rem'/>
                        </div>
                    </Link>
                }
            </div>
        </DarkMessage>
    )
};

export default React.memo(ImpulseUncompleted);