import React from 'react';
import './home.scss';
import {ErrorBoundary} from 'react-error-boundary';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import FallbackComponent from './fallback-component';
import Skeleton from './skeleton/skeleton';
import searchFilter from '../utilities/search-filter';
import XCloseSvg from '../assets/icons0/x-close.svg';
import ShoppingCart01Svg from '../assets/icons0/shopping-cart-01.svg';
import PopUp from './pop-up/pop-up';
import Top from './top';
import Endpoint from './endpoint';
import {graphql, useLazyLoadQuery, useFragment, useMutation} from 'react-relay/hooks';
import useQuery from '../utilities/use-query';
import i18n from '../utilities/i18n';
import SuspenseImage from './suspense-image';
import Browser from './browser';
import {useCartDispatch} from './cart';
import classNames from 'classnames';
import Boom from './boom';
import {useClaimDispatch} from './claim';
import {length, isTrue} from '../utilities/utility';
import useCartStateEx from '../utilities/use-cart-state-ex';
import useClaimStateEx from '../utilities/use-claim-state-ex';
import useSubscriptionStatus from '../utilities/use-subscription-status';
import useSubscription from '../utilities/use-subscription';
import PopUpImpulse from './pop-up-impulse';
import {useBuffetDispatch} from './buffet';
import moment from 'moment';
import ImpulseUncompleted from './impulse-uncompleted';
import Amount from './amount';
import Button, {RouterLinkButton} from './button/button';
import IconLockUnlocked01 from '../assets/icons0/lock-unlocked-01.svg';
import IconActivity from '../assets/icons0/activity.svg';
import {useOperatorDispatch} from './operator-interface/operator-interface';
import getTagValue from '../utilities/get-tag-value';
import {useAuthorizationState} from './authorization';
import {graphqlWhoamiNextQuery} from './graphql';
import LogIn04Svg from '../assets/icons0/log-in-04.svg';
import CheckServiceState from './check-service-state';
import {FormattedMessage} from 'react-intl';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const CardCount = React.memo(({endpointId, serviceId}) => {
  const cartState = useCartStateEx();
  const count = React.useMemo(() =>
    cartState
        .filter(e => e.serviceId === serviceId && e.endpointId === endpointId)
        .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
    [cartState, endpointId, serviceId]
  );
  return (
    <>
    {!!count && 
    <div 
      style={{
        boxShadow: '0px 0.375px 0.75px rgba(16, 24, 40, 0.05)'
      }}
      className='margin-left-0dot25rem display-inline-block background-color-orange-500 border-radius-0dot25rem padding-left-0dot25rem padding-right-0dot25rem text-sm semibold color-white'
    >
    {count}
    </div>
    }
    </>
  );
});

const Card = React.memo(({endpointId, pu, setPu, endpoint, serviceById, endpointMeta}) => {
  const fEndpoint = useFragment(
    graphql`
        fragment homeCardComponents_endpoint on Endpoint {
            id
            name
            nameUk
            details
            detailsUk
            quantity
            price
            end
            ...endpointComponents_endpoint
        }
    `,
    endpoint
  );
  const fServiceById = useFragment(
    graphql`
        fragment homeCardComponents_serviceById on Service {
            id
            ...endpointComponents_serviceById
        }
    `,
    serviceById
  );
  const {locale = 'uk', more} = useQuery();
  const cover = React.useMemo(() => {
    if (i18n(fEndpoint, 'details', locale)) {
        const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
        const found = i18n(fEndpoint, 'details', locale).match(regex);
        return found && found[0];
    }
  }, [fEndpoint, locale]);
  const location = useLocation();
  return (
    <>
    <Browser>
      <PopUp {...{isVisible: pu === endpointId, fullscreen: 'mobile'}}>
        <Endpoint {...{endpoint: fEndpoint, serviceById: fServiceById, close: () => setPu(-1)}}/>
      </PopUp>
    </Browser>
    {/* <Duo 
      Foo={React.memo(({children}) => 
        <Link to={`/endpoint${searchFilter(location.search)}`}>
          <div 
            className='card cursor-pointer mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot5rem'
          >{children}</div>
        </Link>
      )} 
      Bar={React.memo(({children}) => 
        <div 
          className='card cursor-pointer mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot5rem'
          onClick={() => setPu(e)}
        >{children}</div>
      )}
    > */}
    <div 
      className='card cursor-pointer mw768-border-1px-solid mw768-border-color-gray-200 mw768-border-radius-0dot5rem'
      onClick={() => fEndpoint.quantity !== 0 && setPu(endpointId)}
    >
      <div className='mw768-display-none height-0dot0625rem background-color-gray-200'>&nbsp;</div>
      <div className='padding-top-1rem padding-bottom-1rem mw768-padding-left-1rem mw768-padding-right-1rem height-100percent'>
        <div className='display-flex height-100percent'>
          <div className='flex-6 display-flex flex-direction-column'>
            <div className='flex-1'>
              <div className='text-lg semibold color-gray-700'>
                {i18n(fEndpoint, 'name', locale)}
              </div>
              <div className='padding-top-0dot5rem text-sm color-gray-500'>
                {/* {i18n(fEndpoint, 'details', locale)} */}
              </div>
            </div>
            <div className='padding-top-0dot5rem display-flex align-items-center'>
              <div className='text-md semibold color-gray-700'>
                {isTrue(more) ?
                  <>
                    <span>{fEndpoint.price} ₴</span>
                    <span className='padding-left-0dot5rem color-gray-400'>({fEndpoint.quantity} <FormattedMessage defaultMessage='pcs.'/>)</span>
                    {fEndpoint.end && isTrue(more) &&
                      <div className='text-md'>
                        <span className='color-gray-500 regular'>{moment(fEndpoint.end).format('DD.MM.YYYY')}</span>
                      </div>
                    }
                  </>
                  :
                  <>
                    {endpointMeta.soldOut === true
                      && 
                        <div>
                          <span className='text-lg color-gray-400'><FormattedMessage defaultMessage='Sold'/></span>
                        </div>
                      ||
                        <span>{fEndpoint.price} ₴</span>
                    }
                  </>
                }
              </div>
              <Browser>
                <CardCount {...{endpointId: fEndpoint.id, serviceId: fServiceById.id}}/>
              </Browser>
            </div>
          </div>
          <div className='flex-4 padding-left-1rem'>
            <div className='height-100percent display-flex align-items-center justify-content-center'>
              {cover && fEndpoint.quantity !== 0 && 
                <img
                  className='display-block max-width-100percent max-height-100percent border-radius-0dot25rem mw768-border-radius-0dot5rem' 
                  src={cover}
                />
              ||
                <img
                  className='display-block max-width-100percent max-height-100percent border-radius-0dot25rem mw768-border-radius-0dot5rem' 
                  src={cover}
                  style={{opacity: '0.5'}}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </Duo> */}
    </>
  );
});

const MerchantActions = React.memo(({serviceById}) => {
  const {serviceId = serviceIdEx} = useQuery();
  const location = useLocation();
  const dispatch = useOperatorDispatch();
  const navigate = useNavigate();
  const fServiceById = useFragment(
    graphql`
        fragment homeMerchantActionsComponents_serviceById on Service {
            id
            merchant {
              id
            }
            description
            version
        }
    `,
    serviceById
  );
  const tags = fServiceById.description ? fServiceById.description.split(', ') : [];
  const serviceAvailable = getTagValue(tags, 'mmAvailable', ':', true) !== 'false';
  const [mmAvailable, setMmAvailable] = React.useState(serviceAvailable);
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  const [apply, applying] = useMutation(
    graphql`
        mutation homeCardsComponentsWebhookServiceQuery($request: WebhookServiceRequestInput) {
            webhookService(request: $request) {
                id
            }
        }
  `);
  const submit = () => {
    apply({
        variables: {
            request: {
                id: fServiceById.id,
                version: fServiceById.version,
                event: 'service.open'
            }
        },
        onCompleted: () => {},
        onError: () => {}
    });
  };
  const startService = () => {
    dispatch({type: 'start', payload: {serviceId}});
    navigate(`/operator-interface${searchFilter(location.search)}`);
  };
  const [updateServiceDescription, updatingServiceDescription] = useMutation(
    graphql`
        mutation homeMerchantActionsServiceMutation($request: UpdateServiceDescriptionRequestInput) {
            updateServiceDescription(request: $request) {
                id
                description
                version
            }
        }
  `);
  const updateServiceTags = (mmAvailable) => {
    const tagList = tags.filter(e => !e.startsWith('mmAvailable'));
    tagList.push(`mmAvailable:${mmAvailable}`);
    updateServiceDescription({
      variables: {request: {
          id: fServiceById.id,
          version: fServiceById.version,
          description: tagList.join(', ')
      }},
      onCompleted: () => {setMmAvailable(mmAvailable)},
      onError: () => {}
    });
  };
  return (
    <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem padding-top-1rem padding-bottom-1rem'>
      {fServiceById.merchant.id === whoamiNext.id &&
        <label htmlFor='mmAvailable' className={classNames('display-flex padding-bottom-1dot5rem', {'cursor-pointer': !updatingServiceDescription, 'pointer-events-none opacity-0dot4': updatingServiceDescription})}>
          <input id='mmAvailable' type='checkbox' className='display-none' checked={mmAvailable} onChange={() => {updateServiceTags(!mmAvailable)}} disabled={updatingServiceDescription}/>
          <div className={classNames('toggle', {'toggle--active': mmAvailable})}></div>
          <div className='padding-left-0dot75rem text-md'>
              <p className={classNames('medium', {'color-gray-700': mmAvailable, 'color-gray-400': !mmAvailable})}>
                {mmAvailable ? <FormattedMessage defaultMessage='Opened'/> : <FormattedMessage defaultMessage='Closed'/>}
              </p>
          </div>
        </label>
      }
      <div className='display-flex'>
        {fServiceById.merchant.id === whoamiNext.id &&
          <Button {...{size: 'md', color: 'secondary-gray', clickHandler: startService}}>
            <IconActivity className='width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
            <span><FormattedMessage defaultMessage='Maintenance'/></span>
          </Button>
        }
        {fServiceById.merchant.id !== whoamiNext.id &&
          <RouterLinkButton {...{size: 'md', color: 'secondary-gray', to: `/log-out${searchFilter(location.search)}`}}>
            <LogIn04Svg className='width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
            <span><FormattedMessage defaultMessage='Sign in'/></span>
          </RouterLinkButton>
        }
        <div className='padding-left-0dot75rem'>
          <Button {...{size: 'md', color: 'primary-gradient', clickHandler: () => !applying && submit(), disabled: applying}}>
            <IconLockUnlocked01 className='width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
            <span><FormattedMessage defaultMessage='Open'/></span>
          </Button>
        </div>
      </div>
    </div>
  )
});

const Cards = React.memo(() => {
  const {serviceId = serviceIdEx} = useQuery();
  const {serviceById} = useLazyLoadQuery(
    graphql`
        query homeCardsComponentsServiceByIdQuery($id: String) {
            serviceById(id: $id) {
              id
              version
              name
              nameUk
              details
              detailsUk
              location
              locationUk
              endpoints {
                  id
                  tags
                  quantity
                  end
                  ...homeCardComponents_endpoint
              }
              ...homeCardComponents_serviceById
              ...homeMerchantActionsComponents_serviceById
            }
        }
    `,
    {id: serviceId}
  );
  const {locale = 'uk', more} = useQuery();
  const cover = React.useMemo(() => {
    if (i18n(serviceById, 'details', locale)) {
        const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
        const found = i18n(serviceById, 'details', locale).match(regex);
        return found && found[0];
    }
  }, [serviceById, locale]);
  const [endpointMetaMap, setEndpointMetaMap] = React.useState({});
  const endpointsToShow = React.useMemo(() => {
    return serviceById.endpoints.filter(e => getTagValue(e.tags, 'mmDisplay', ':', false) === 'true');
  }, [serviceById]);
  const availableEndpoints = React.useMemo(
    () => {
      setEndpointMetaMap(() => endpointsToShow.reduce((result, e) => {
        const endpointHasEndTime = !!e.end;
        const endTimeIsInFuture = e.end && moment(e.end).diff(moment()) > 0;
        const notAvailable = e.quantity === 0;
        if (notAvailable) {
          return {...result, [e.id]: {soldOut: true, name: e.name}};
        }
        if (endpointHasEndTime && !endTimeIsInFuture) {
          return {...result, [e.id]: {soldOut: true, name: e.name}};
        }
        return {...result, [e.id]: {}};
      }, {}));
      return endpointsToShow;
    },
    [endpointsToShow, more]
  );
  const endpoints = React.useMemo(
    () => availableEndpoints.slice().sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [availableEndpoints]);
  const [pu, setPu] = React.useState();
  const authorizationState = useAuthorizationState();
  return (
    <>
    <Helmet>
        <title>{i18n(serviceById, 'name', locale)}</title>
        <meta name='description' content={i18n(serviceById, 'name', locale)}/>
        <meta property='og:image' content={cover}/>
    </Helmet>
    <div className='mw768-padding-left-2rem mw768-padding-right-2rem mw768-padding-top-1rem'>
      <div className='display-flex flex-direction-column mw768-flex-direction-row mw768-justify-content-space-between'>
        <div className='mw768-flex-1'>
          {cover &&
          <SuspenseImage
            className='display-block margin-0-auto mw768-margin-right-0 max-width-100percent max-height-11rem mw768-max-height-15rem mw768-border-radius-0dot75rem mm-image'
            src={cover}
          />
          }
          {/* {cover && <img
              className='display-block object-fit-contain width-100percent max-height-15rem'
              src={cover}
          />} */}
        </div>
        <div className='mw768-flex-order-minus1 padding-left-1dot25rem mw768-padding-left-0 padding-right-1dot25rem mw768-padding-right-1rem mw768-flex-1'>
          <div className='padding-top-1dot5rem mw768-padding-top-0 display-sm mw768-display-lg semibold color-gray-900'>{i18n(serviceById, 'name', locale)}</div>
          {!!i18n(serviceById, 'location', locale) &&
            <div className='padding-top-0dot75rem mw768-padding-top-0dot5rem text-md mw768-text-xl color-gray-500'>
              {i18n(serviceById, 'location', locale)}
            </div>
          }
        </div>
        {isTrue(more) && authorizationState &&
          <MerchantActions {...{serviceById}}/>
        }
      </div>
    </div>
    <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem'>
      <div className='padding-top-3rem mw768-padding-top-2rem'>
        <div className='display-xs semibold color-gray-900'>
          <FormattedMessage defaultMessage='Products'/>
        </div>
      </div>
      <div className='padding-top-1dot5rem mw768-padding-top-2rem mw768-padding-bottom-2rem'>
        <div 
          className='mw768-display-grid'
          style={{
            gridTemplateColumns: '2fr 1fr',
            columnGap: '2rem',
          }}
        >
          <div>
            <div
              className='display-grid grid-cols-1 mw768-grid-cols-2 mw768-column-gap-2rem mw768-row-gap-2rem'
              style={{
                gridAutoRows: '1fr',
              }}
            >
              {endpoints.map((endpoint) =>
                <Card key={endpoint.id} {...{endpointId: endpoint.id, pu, setPu, endpoint, serviceById, endpointMeta: endpointMetaMap[endpoint.id]}}/>
              )}
            </div>
          </div>
          <div className='display-none mw768-display-block'>
            <Browser fallback={
              <div 
                className='border-radius-1rem sticky top-2rem'
                style={{
                  boxShadow: '0px 8px 24px 0px #10182829'
                }}
              >
                <div className='padding-1rem'>
                  <Skeleton/>
                </div>
              </div>
            }>
              <React.Suspense fallback={
                <div 
                  className='border-radius-1rem sticky top-2rem'
                  style={{
                    boxShadow: '0px 8px 24px 0px #10182829'
                  }}
                >
                  <div className='padding-1rem'>
                    <Skeleton/>
                  </div>
                </div>
              }>
                <Side/>
              </React.Suspense>
            </Browser>
          </div>
        </div>
      </div>
    </div>
    </>
  );
});

const Meta = React.memo(({cartState, entry}) => {
  const {locale = 'uk'} = useQuery();
  const {endpointById, serviceById} = useLazyLoadQuery(
    graphql`
        query homeMetaComponentsEndpointByIdServiceByIdQuery($endpointId: String, $serviceId: String) {
            endpointById(id: $endpointId) {
                id
                name
                nameUk
                price
            }
            serviceById(id: $serviceId) {
                id
                name
                nameUk
            }
        }
    `,
    {endpointId: entry.endpointId, serviceId: entry.serviceId}
  );
  const cartDispatch = useCartDispatch();
  const count = React.useMemo(() =>
    cartState
        .filter(e => e.serviceId === serviceById.id && e.endpointId === endpointById.id)
        .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
    [cartState, endpointById.id, serviceById.id]
  );
  return (
    <div className='padding-0dot5rem'>
      <div className='display-flex justify-content-space-between'>
        <div>
          <div className='display-flex'>
            <div className='text-md semibold color-gray-700'>
              {i18n(endpointById, 'name', locale)}
            </div>
            <div className='padding-left-0dot375rem text-md medium color-orange-600'>
              х{count}
            </div>
          </div>
          <div className='padding-top-0dot25rem'>
            <div className='display-flex align-items-center'>
              <div 
                className='cursor-pointer display-flex align-items-center'
                onClick={() => {
                  cartDispatch({
                    type: 'remove', 
                    payload: {endpointId: endpointById.id, serviceId: serviceById.id}
                  });
                }}
              >
                <XCloseSvg className='display-block height-0dot75rem width-0dot75rem color-gray-500'/>
                <div className='padding-left-0dot25rem text-xs medium color-gray-500'>
                  <FormattedMessage defaultMessage='Remove'/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='text-md semibold color-gray-700'>
          80.00 ₴
        </div> */}
      </div>
    </div>
  );
});

const Metas = React.memo(() => {
  const cartState = useCartStateEx();
  const cartStateDeferred = React.useDeferredValue(cartState);
  return (
    <>
    <div className='padding-top-1rem padding-left-1rem padding-right-1rem'>
        {cartState.map(entry => 
          <React.Suspense key={entry.key} fallback={
            <div className='padding-0dot5rem'>
              <Skeleton/>
            </div>
          }>
            <ErrorBoundary {...{FallbackComponent}}>
              <Meta {...{entry, cartState}}/>
            </ErrorBoundary>
          </React.Suspense>
        )}
    </div>
    <div className='padding-top-1rem'>
      <div className='height-0dot0625rem background-color-gray-200'>&nbsp;</div>
    </div>
    <div className='padding-top-1rem padding-bottom-1rem'>
      <div className='padding-left-1dot5rem padding-right-1dot5rem display-flex justify-content-space-between'>
        <div className='text-md semibold color-gray-700'>
          <FormattedMessage defaultMessage='Total'/>
        </div>
        <div className='text-md semibold color-gray-700'>
          <React.Suspense>
            <ErrorBoundary {...{FallbackComponent}}>
              <Amount {...{
                cartState: cartStateDeferred,
                flag: cartState !== cartStateDeferred
              }}/>
            </ErrorBoundary>
          </React.Suspense>
        </div>
      </div>
    </div>
    </>
  );
});

const OpenImpl = React.memo(({refreshedQueryOptions, flag}) => {
  const claimDispatch = useClaimDispatch();
  const buffetDispatch = useBuffetDispatch();
  const claimState = useClaimStateEx();
  const {impulseByClaim} = useLazyLoadQuery(
    graphql`
      query homeOpenImplComponentsImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
          impulseByClaim(request: $claimRequest) {
              id
              impulseService {
                  service {
                      id
                      version
                  }
              }
              status
              claim
          }
      }
    `,
    refreshedQueryOptions.variables,
    refreshedQueryOptions.options
  );
  const [apply, applying] = useMutation(
    graphql`
        mutation homeOpenImplComponentsWebhookServiceQuery($request: WebhookServiceRequestInput) {
            webhookService(request: $request) {
                id
            }
        }
  `);
  const submit = () => {
    apply({
        variables: {
            request: {
                id: impulseByClaim.impulseService.service.id,
                version: impulseByClaim.impulseService.service.version,
                event: 'service.open'
            }
        },
        onCompleted: () => {
          buffetDispatch({type: 'add', payload: {id: impulseByClaim.id, serviceId: impulseByClaim.impulseService.service.id, claim: impulseByClaim.claim, opened: new Date()}});
          claimDispatch({type: 'remove', payload: {id: impulseByClaim.id}});
        },
        onError: () => {}
    });
  };
  const impulseUncompleted = !claimState[0].successPageVisited && impulseByClaim.status !== 4;
  return (
    <>
    {!impulseUncompleted ?
    <div className={classNames({'opacity-0dot6': flag})}>
      <div className='display-flex justify-content-space-between'>
        {impulseByClaim.status === 4 ?
          <div
            style={{
              background: 'linear-gradient(88.92deg, #039855 0%, #12B76A 100%)',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              // boxShadow: '0px -24px 48px -12px rgba(16, 24, 40, 0.05)'
            }}
            className='flex-1 cursor-pointer border-radius-0dot5rem padding-1rem'
            onClick={() => impulseByClaim.status === 4 && !applying && submit()}
          >
            <div className='text-md medium color-white display-flex justify-content-center'>
              <FormattedMessage defaultMessage='Open micromarket'/>{applying && '...'}
            </div>
          </div>
          :
          <div
            style={{
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
            className='flex-1 cursor-pointer border-radius-0dot5rem padding-1rem background-color-gray-200'
          >
            <div className='text-md medium color-white display-flex justify-content-center'>
              <FormattedMessage defaultMessage='Open micromarket'/>
            </div>
          </div>
        }
        {[6].includes(impulseByClaim.status) && 
          <div 
            className='margin-left-1rem cursor-pointer display-flex align-items-center'
            onClick={() => claimDispatch({type: 'remove', payload: {id: impulseByClaim.id}})}
          >
            <XCloseSvg className='display-block height-0dot75rem width-0dot75rem color-gray-500'/>
            <div className='padding-left-0dot25rem text-xs medium color-gray-500'>
              <FormattedMessage defaultMessage='New order'/>
            </div>
          </div>
        }
      </div>
    </div>
    :
    <ImpulseUncompleted {...{impulseId: impulseByClaim.id, impulseExpired: [6].includes(impulseByClaim.status)}}/>
    }
    </>
  );
});

const Open = React.memo(() => {
  const claimState = useClaimStateEx();
  const impulseId = claimState[0].id;
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
    options: {fetchKey: 0, fetchPolicy: 'network-only'},
    variables: {claimRequest: {value: claimState[0].claim, fetchKey: 0}}
  });
  const [isPending, startTransition] = React.useTransition();
  const refresh = React.useCallback(() => {
    startTransition(() => {
        setRefreshedQueryOptions(prev => ({
            ...prev, 
            options: {...prev.options, fetchKey: prev.options.fetchKey + 1},
            variables: {...prev.variables, claimRequest: {...prev.variables.claimRequest, fetchKey: prev.variables.claimRequest.fetchKey + 1}}
        }));
    });
  }, []);
  const payload = React.useMemo(() => ({
    channel: `/impulses/${impulseId}`,
    onNext: () => refresh(),
    onError: () => {}
  }), [refresh, impulseId]);
  useSubscription(payload);
  const subscriptionStatus = useSubscriptionStatus();
  React.useEffect(() => {
      if (subscriptionStatus === 0) refresh();
  }, [subscriptionStatus, refresh]);
  return (
    <OpenImpl {...{
      refreshedQueryOptions,
      flag: isPending
    }}/>
  );
});

const Side = React.memo(() => {
  const location = useLocation();
  const cartState = useCartStateEx();
  const count = React.useMemo(() =>
    cartState
      .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
    [cartState]
  );
  const claimState = useClaimStateEx();
  return (
    <div 
      className='side-box border-radius-1rem sticky top-2rem padding-1rem'
    >
      {length(claimState) ?
        <>
          <Open/>
          <div className='side-box-text display-flex justify-content-center padding-top-1dot5rem text-sm color-gray-500'>
            <FormattedMessage defaultMessage='Please pick up the items from your order.'/> 
          </div>
        </>
        :
        <>
        {!!count ?
          <>
          <div>
            <Link to={`/check-out${searchFilter(location.search, {landId: 0})}`}>
              <div
                style={{
                  background: 'linear-gradient(88.92deg, #EC4A0A 0%, #FB6514 100%)',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                }}
                className='border-radius-0dot5rem padding-1rem'
              >
                <div className='flex-1 text-md medium color-white display-flex justify-content-center'>
                  <FormattedMessage defaultMessage='Buy'/>
                </div>
              </div>
            </Link>
          </div>
          <div className='height-0dot0625rem background-color-gray-200'>&nbsp;</div>
          <Metas/>
          </>
          :
          <div>
            <div
              className='cursor-pointer padding-1rem display-flex justify-content-center border-radius-0dot5rem background-color-gray-300'
              style={{
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.1)'
              }}
            >
              <div className='text-md medium color-white'><FormattedMessage defaultMessage='Buy'/></div>
            </div>
            <div className='display-flex justify-content-center padding-top-1dot5rem text-sm color-gray-500'>
              <FormattedMessage defaultMessage='Select products and add them to the order'/>
            </div>
          </div>
        }
        </>
      }
    </div>
  );
});

const AmountEx = React.memo(() => {
  const cartState = useCartStateEx();
  const cartStateDeferred = React.useDeferredValue(cartState);
  return (
    <Amount {...{
      cartState: cartStateDeferred,
      flag: cartState !== cartStateDeferred
    }}/>
  );
}); 

const Outcome = React.memo(() => {
  const location = useLocation();
  const cartState = useCartStateEx();
  const count = React.useMemo(() =>
    cartState
      .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
    [cartState]
  );
  const claimState = useClaimStateEx();
  return (
    <div className={classNames({'display-none': !count && !length(claimState)})}>
      <div className='foo bar position-fixed width-100percent bottom-0 padding-1dot25rem background-color-white'>
        {length(claimState) ?
          <Open/>
          :
          <Link to={`/check-out${searchFilter(location.search, {landId: 0})}`}>
            <div
              style={{
                background: 'linear-gradient(88.92deg, #EC4A0A 0%, #FB6514 100%)',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
              }}
              className='border-radius-0dot5rem padding-1rem'
            >
              <div className='padding-left-0dot25rem padding-right-0dot25rem'>
                <div className='display-flex'>
                  <div className='flex-1'>
                    <div className='display-flex align-items-center'>
                      <ShoppingCart01Svg className='display-block height-1dot5rem width-1dot5rem color-white'/>
                      <div className='padding-left-0dot75rem text-md medium color-white'>
                        {count}
                      </div>
                    </div>
                  </div>
                  <div className='flex-1 text-md medium color-white display-flex justify-content-center'>
                    <FormattedMessage defaultMessage='Buy'/>
                  </div>
                  <div className='flex-1 text-md medium color-white display-flex justify-content-flex-end'>
                    {!!count && 
                    <React.Suspense>
                      <ErrorBoundary {...{FallbackComponent}}>
                        <AmountEx/>
                      </ErrorBoundary>
                    </React.Suspense>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Link>
        }
      </div>
    </div>
  );
});

export default React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Time to eat home</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <ErrorBoundary {...{FallbackComponent: Boom}}>
      <div className='home active'>
        <div className='mw768-max-width-80rem mw768-margin-0-auto'>
          <Top/>
          <React.Suspense fallback={
            <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem mw768-padding-top-1rem'>
              <Skeleton/>
            </div>
          }>
            <ErrorBoundary {...{FallbackComponent}}>
              <Cards/>
              <CheckServiceState {...{canDismiss: true}}/>
              <Browser>
                <React.Suspense>
                  <ErrorBoundary {...{FallbackComponent}}>
                    <Outcome/>
                    <PopUpImpulse/>
                  </ErrorBoundary>
                </React.Suspense>
              </Browser>
            </ErrorBoundary>
          </React.Suspense>
        </div>
      </div>
    </ErrorBoundary>
    </>
  );
});
