import Button, {RouterLinkButton} from '../button/button';
import {Link, useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import Lock from '../../assets/icons0/lock-unlocked-01.svg';
import Search from '../../assets/icons0/search-md.svg';
import QrCode from '../../assets/icons0/qr-code-scan-01.svg';
import ArrowLeft from '../../assets/icons0/arrow-left.svg';
import ArrowRightIcon from '../../assets/icons0/arrow-right.svg';
import ScanSvg from './../../assets/scan.svg';
import StepsBar from './steps-bar';
import useQuery from '../../utilities/use-query';
import {useOperatorState} from './operator-interface';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {graphqlOperatorInterfaceServiceByIdQuery} from './graphql';
import React from 'react';
import getImageUrl from './get-image-url';
import searchFilter from '../../utilities/search-filter';
import getTagValue from '../../utilities/get-tag-value';
import {isNullOrUndefined} from '../../utilities/utility';
import i18n from '../../utilities/i18n';
import {FormattedMessage} from 'react-intl';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const EndpointRow = React.memo(({item}) => {
  const {serviceId = serviceIdEx, locale = 'uk'} = useQuery();
  const location = useLocation();
  const img = getImageUrl(item.details);
  const state = useOperatorState()[serviceId];
  const endpointState = state.endpoints[item.id] || {};
  return (
    <Link className='table-row display-flex justify-content-space-between border-bottom align-items-center' to={`../step-5-2${searchFilter(location.search, {endpointId: item.id})}`}>
      <div className='cover width-2dot5rem'>
        <img className='width-2dot5rem height-2dot5rem border-radius-0dot5rem' src={img}/>
      </div>
      <div className='margin-left-0dot625rem flex-grow-1'>
        <div className='text-sm medium color-gray-900'>
          {i18n(item, 'name', locale)}
        </div>
        <div className='text-sm color-gray-500 regular'>
          {endpointState.end && moment(endpointState.end).format('DD.MM.YYYY')}
        </div>
      </div>
      <div className='text-sm color-gray-500' >
        {endpointState.income}
      </div>
    </Link>
  )
});

const Step5 = () => {
  const navigate = useNavigate();
  const {serviceId = serviceIdEx} = useQuery();
  const {serviceById} = useLazyLoadQuery(graphqlOperatorInterfaceServiceByIdQuery, {id: serviceId});
  const state = useOperatorState()[serviceId];
  const endpointsState = state.endpoints;
  const addedItems = React.useMemo(
    () => serviceById.endpoints.filter((item) => endpointsState[item.id] && !isNullOrUndefined(endpointsState[item.id].income)).sort((a, b) => getTagValue(a.tags, 'rank', ':', Infinity) - getTagValue(b.tags, 'rank', ':', Infinity)), 
  [serviceById]);
  const location = useLocation();
  const [apply, applying] = useMutation(
    graphql`
        mutation step5WebhookServiceQuery($request: WebhookServiceRequestInput) {
            webhookService(request: $request) {
                id
            }
        }
  `);
  const open = () => {
    apply({
        variables: {
            request: {
                id: serviceById.id,
                version: serviceById.version,
                event: 'service.open'
            }
        },
        onCompleted: () => {},
        onError: () => {}
    });
  };
  return (<>
    <div className='mm-header background-color-gray-50'>
      <div className='lock-button' onClick={() => !applying && open()}>
        <Lock className='display-block color-gray-700 height-1dot25rem'/>   
      </div>
      <StepsBar/>
      <Link to={`../step-4${searchFilter(location.search)}`}>
        <ArrowLeft className='display-block color-gray-500 height-1dot5rem'/>  
      </Link>
    </div>
    <div className='header-padding background-color-gray-50'>
      <div className='text-2xl medium color-gray-900 margin-bottom-1rem'><FormattedMessage defaultMessage='Micromarket replenishment'/></div>
      <div className='search-input-with-scan-button'>
        <Search className='search-icon'/>
        <input id='filter'
          name='filter'
          type='text'
          value={''}
          className='form-row__input'
          placeholder='Пошук товарів'
          onFocus={() => navigate(`../step-5-1${searchFilter(location.search)}`)}
          onChange={() => {}}
        />
        <Button
          {...{
            clickHandler: () => false,
            isLoading: false,
            fluid: 'always',
            disabled: false,
            additionalClasses: 'qr-code-button',
          }}
        >
          <QrCode className='qr-code-icon'/>
        </Button>
      </div>
    </div>
    <div className='fulfill-title text-lg medium border-bottom display-flex align-items-center justify-content-space-between'>
      <span className='color-gray-900'><FormattedMessage defaultMessage='List of replenishments'/></span>
      <div className='width-2dot5rem'>
        <RouterLinkButton {...{color: 'primary-secondary', size: 'md', fluid: 'always', to: `../step-7${searchFilter(location.search)}`, additionalClasses: 'shadow-xs'}}>
          <ArrowRightIcon className='display-block height-1dot25rem width-1dot25rem'/>
        </RouterLinkButton>
      </div>
    </div>
    {addedItems.length === 0 && 
      <div className='no-results-container'>
        <div className='content-wrapper display-flex justify-content-center'>
          <ScanSvg className='scan-img'/>
        </div>
        <div className='margin-top-1dot25rem'>
          <h2 className='color-gray-900 text-align-center medium text-lg'><FormattedMessage defaultMessage='No products to replenish'/></h2>
          <p className='color-gray-500 text-align-center regular margin-top-0dot5rem text-sm'><FormattedMessage defaultMessage="Use the search or scan the product's QR code to add it to the list"/></p>
        </div>
        {/* <div className='display-flex gap-12 margin-top-2rem'>
          <div className='flex-basis-50percent'>
            <RouterLinkButton
              {...{
                color: 'white-gradient',
                size: 'md',
                to: `../step-5-1${searchFilter(location.search)}`,
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              <Search className='width-1dot125rem height-1dot125rem margin-right-0dot625rem'/>
              Пошук
            </RouterLinkButton>
          </div>
          <div className='flex-basis-50percent'>
            <RouterLinkButton
              {...{
                color: 'blue-gradient',
                size: 'md',
                to: `../step-7${searchFilter(location.search)}`,
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              <QrCode className='width-1dot125rem height-1dot125rem margin-right-0dot625rem'/>
              Сканер
            </RouterLinkButton>
          </div>
        </div> */}
      </div>
    }
    {addedItems.length > 0 &&
      <div className='padding-bottom-1dot125rem'>
        <div className='container-padding padding-0dot5rem thead display-flex background-color-gray-50 border-bottom justify-content-space-between'>
          <div className='thead-left text-xs color-gray-500'>
            <FormattedMessage defaultMessage='Product name'/>
          </div>
          <div className='thead-right text-xs color-gray-500'>
            <FormattedMessage defaultMessage='Quantity'/>
          </div>
        </div>
        {addedItems.map(item => 
          <EndpointRow key={item.id} {...{item}}/>
        )}
      </div>
    }
  </>);
};

export default React.memo(Step5);