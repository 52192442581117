import {RouterLinkButton} from '../button/button';
import {Link, useLocation} from 'react-router-dom';
import Close from '../../assets/icons/x.svg';
import SolidTickIcon from '../../assets/icons0/solid-tick.svg';
import moment from 'moment';
import classNames from 'classnames';
import searchFilter from '../../utilities/search-filter';
import {FormattedMessage} from 'react-intl';

const STEPS = [{
  title: <FormattedMessage defaultMessage='Check balances'/>,
  description: <FormattedMessage defaultMessage='Check the leftovers in micromarket and adjust the quantity of the product if necessary'/>,
  value: '2',
  actionTitle: <FormattedMessage defaultMessage='Check balances'/>,
  actionLink: '../step-3',
  showBack: false,
  backLink: '/'
}, {
  title: <FormattedMessage defaultMessage='Replenish micromarket'/>,
  description: <FormattedMessage defaultMessage='Mark the amount of product you have added to the refrigerator'/>,
  value: '4',
  actionTitle: <FormattedMessage defaultMessage='Replenish'/>,
  actionLink: '../step-5',
  showBack: true,
  backLink: '../step-3'
}, {
  title: <FormattedMessage defaultMessage='Create report'/>,
  description: <FormattedMessage defaultMessage='Create report with the number of products in the refrigerator (written off, added)'/>,
  value: '7', 
  actionTitle: <FormattedMessage defaultMessage='Report'/>,
  actionLink: '../step-8',
  showBack: true,
  backLink: '../step-5'
}];

const StepsProgress = () => {
  const location = useLocation();
  const currentStep = location.pathname.split('/step-')[1].replace('/', '');
  const activeStepIndex = STEPS.findIndex(step => step.value === currentStep);
  return (
    <>
      <div className='mm-header'>
        <Link to={`/${searchFilter(location.search)}`}>
          <Close className='icon-24 display-block color-gray-500'/>  
        </Link>
      </div>
      <div className='padding-left-1rem padding-right-1rem'>
        <div className='text-2xl medium color-gray-900'><FormattedMessage defaultMessage='Maintenance'/></div>
        <div className='text-md color-gray-500 margin-top-0dot25rem'>{moment().locale('uk').format('DD MMMM YYYY ')} <FormattedMessage defaultMessage='yr'/></div>
        <div className='content-wrapper-steps'>
          <div className='steps-ui'>
            {STEPS.map((step, index) =>
              <div className={classNames('step-ui', {'step-ui--active': index === activeStepIndex, 'step-ui--completed': index < activeStepIndex})} key={index}>
                <div className='step-ui--icon-wrapper'>
                  <div className='step-ui--icon'>
                    <SolidTickIcon className='height-1dot25rem width-1dot25rem display-block'/>
                  </div>
                  <div className='step-ui--bar-wrapper'>
                    <div className='step-ui--bar'></div>
                  </div>
                </div>
                <div className='step-ui--content'>
                  <h3 className='step-title'>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='display-flex gap-12'>
          <div className={classNames('flex-basis-50percent', {'display-none': !STEPS[activeStepIndex].showBack})}>
            <RouterLinkButton
              {...{
                color: 'secondary-gray-mm',
                size: 'xl',
                to: `${STEPS[activeStepIndex].backLink}${searchFilter(location.search)}`,
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              <FormattedMessage defaultMessage='Back'/>
            </RouterLinkButton>
          </div>
          <div className={classNames({'flex-basis-50percent': STEPS[activeStepIndex].showBack, 'flex-1': !STEPS[activeStepIndex].showBack})}>
            <RouterLinkButton
              {...{
                color: 'blue-gradient',
                size: 'xl',
                to: `${STEPS[activeStepIndex].actionLink}${searchFilter(location.search)}`,
                isLoading: false,
                fluid: 'always',
                disabled: false
              }}
            >
              {STEPS[activeStepIndex].actionTitle}
            </RouterLinkButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(StepsProgress);