/**
 * @generated SignedSource<<d17beddcd0d6ed913d8f7b602cddaa1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "serviceById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Endpoint",
        "kind": "LinkedField",
        "name": "endpoints",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphqlOperatorInterfaceServiceByIdQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphqlOperatorInterfaceServiceByIdQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "32c36d5f322a8bab19154188e376a7ae",
    "id": null,
    "metadata": {},
    "name": "graphqlOperatorInterfaceServiceByIdQuery",
    "operationKind": "query",
    "text": "query graphqlOperatorInterfaceServiceByIdQuery(\n  $id: String\n) {\n  serviceById(id: $id) {\n    id\n    name\n    endpoints {\n      id\n      name\n      nameUk\n      details\n      tags\n      quantity\n      end\n      version\n    }\n    version\n  }\n}\n"
  }
};
})();

node.hash = "f053b9b113b3e2fcf9bb5946a5f79b03";

module.exports = node;
