import React from 'react';
import useQuery from '../utilities/use-query';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import getTagValue from '../utilities/get-tag-value';
import {useAuthorizationState} from './authorization';
import PopUp from './pop-up/pop-up';
import {graphqlWhoamiNextQuery} from './graphql';
import IconFeature from './icon-feature/icon-feature';
import AlertCircleIcon from '../assets/icons0/alert-circle.svg';
import XCloseIcon from '../assets/icons0/x-close.svg';
import './check-service-state.scss';
import Button from './button/button';
import {FormattedMessage} from 'react-intl';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const MerchantCloseAction = ({merchantId, children}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    if (whoamiNext.id !== merchantId) {
        return null;
    }
    return (
        <>
            {children}
        </>
    )
}

const CloseAction = ({close}) => {
    return (
        <div className='position-absolute right-1rem top-1rem z-index-10'>
            <Button {...{plain: true, color: 'mute', size: 'md', clickHandler: close}}>
                <XCloseIcon className='display-block height-1dot5rem width-1dot5rem color-gray-200'/>
            </Button>
        </div>
    )
};

const CheckServiceState = ({canDismiss = false}) => {
    const {serviceId = serviceIdEx, more} = useQuery();
    const {serviceById} = useLazyLoadQuery(
        graphql`
            query checkServiceStateServiceByIdQuery($id: String) {
                serviceById(id: $id) {
                    id
                    version
                    description
                    merchant {
                        id
                    }
                }
            }
        `,
        {id: serviceId}
    );
    const authorizationState = useAuthorizationState();
    const tags = serviceById.description ? serviceById.description.split(', ') : [];
    const serviceAvailable = getTagValue(tags, 'mmAvailable', ':', true) !== 'false';
    const [closeError, setCloseError] = React.useState(false);
    return (
        <div className='check-service-state'>
            <PopUp isVisible={!serviceAvailable && !closeError}>
                <div className='padding-top-1dot25rem padding-right-1rem padding-left-1rem padding-bottom-1rem text-align-center'>
                    <IconFeature {...{size: 'md', feature: 'outline', color: 'error'}}>
                        <AlertCircleIcon className='display-block'/>
                    </IconFeature>
                    <p className='text-lg color-gray-900 padding-top-1rem'><FormattedMessage defaultMessage='Micromarket is temporarily unavailable'/></p>
                </div>
            </PopUp>
            {!serviceAvailable && more === 'true' && canDismiss && !closeError &&
                <CloseAction {...{close: () => {setCloseError(true)}}}/>
            }
            {!serviceAvailable && authorizationState && more !== 'true' && canDismiss && !closeError &&
                <MerchantCloseAction {...{merchantId: serviceById.merchant.id}}>
                    <CloseAction {...{close: () => {setCloseError(true)}}}/>
                </MerchantCloseAction>
            }
        </div>
    );
};

export default React.memo(CheckServiceState);