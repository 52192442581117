import React from 'react';
import './check-out.scss';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../utilities/search-filter';
import Locale from './locale';
import GroupSvg from '../assets/group.svg';
import FebruaryPng from '../assets/february2.png';
import MarchSvg from '../assets/march.svg';
import HelpCircleSvg from '../assets/icons0/help-circle.svg';
import Xsvg from '../assets/icons0/x.svg';
import ArrowCircleBrokenRightSvg from '../assets/icons0/arrow-circle-broken-right.svg';
import AccountButtonSvg from '../assets/account-button.svg';
import {useAuthorizationState} from './authorization';
import SignIn from './sign-in';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {graphqlWhoamiNextQuery} from './graphql';
import classNames from 'classnames';
import './top.scss';
import LogoText from '../assets/logo-text.svg';
import useQuery from '../utilities/use-query';
import {FormattedMessage} from 'react-intl';

const legal = 'https://www.time2eat.today/legal';
const demoServiceId = process.env.RAZZLE_APP_DEMO_SERVICE_ID;
const SUPPORT = {
  ottry: {
    formatted: '+38 (097) 938-11-62',
    number: '+380979381162'
  },
  default : {
    formatted: '0-800-33-56-89',
    number: '0800335689'
  }
}

const User = React.memo(({userMenuRef, toggleUserMenu}) => {
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  return (
    <>
    <div className='display-none'>{whoamiNext.username || whoamiNext.email}</div>
    <div className='cursor-pointer' ref={userMenuRef} onClick={toggleUserMenu}>
      <AccountButtonSvg className='display-block height-2dot5rem width-2dot5rem' />
    </div>
    </>
  );
});

const UserMenu = React.memo(({userMenuRef, userMenuFlag, toggleUserMenu}) => {
  const location = useLocation();
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  return (
      <div
          ref={userMenuRef}
          className={classNames('display-none', {'display-block': userMenuFlag})}
      >
          {whoamiNext &&
            <div className='position-absolute background-color-white border-1px-solid border-color-gray-100 border-radius-0dot5rem z-index-1 user-dropdown' style={{top: 44, right: 0}}>
              <div className='padding-left-1rem padding-right-1rem padding-top-0dot75rem padding-bottom-0dot75rem border-bottom-1px-solid border-color-gray-100'>
                <div className='text-sm medium color-gray-900'>
                  {whoamiNext.username || whoamiNext.email}
                </div>
              </div>
              <div className='padding-top-0dot25rem'>
                <Link to={`/sign-out${searchFilter(location.search)}`} onClick={toggleUserMenu}>
                  <div className='display-flex padding-left-1rem padding-right-1rem padding-top-0dot5rem padding-bottom-0dot75rem'>
                    <div className='text-sm medium color-gray-500'>
                      <FormattedMessage defaultMessage='Sign out'/>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          }
      </div>
  );
});

const Auth = React.memo(() => {
  const authorizationState = useAuthorizationState();
  const [flag, setFlag] = React.useState();
  const userMenuRef = React.useRef();
  const [userMenuFlag, setUserMenuFlag] = React.useState(false);
  React.useEffect(() => {
    const onClickOutsideHandler = (event) => {
        if (userMenuFlag && !(
          userMenuRef.current.contains(event.target)
        )) setUserMenuFlag(false);
    };
    document.addEventListener('click', onClickOutsideHandler, true);
    return () => {
        document.removeEventListener('click', onClickOutsideHandler, true);
    };
  }, [userMenuRef, userMenuFlag, userMenuFlag]);
  const toggleUserMenu = React.useCallback(() => setUserMenuFlag((flag) => !flag), []);
  return (
    <>
    {flag && <div className='display-none'><SignIn/></div>}
    {authorizationState ? 
      <React.Suspense fallback={
        <div className='display-block height-2dot5rem width-2dot5rem'>&nbsp;</div>
      }>
        <div className='position-relative'>
          <User {...{userMenuRef, toggleUserMenu}}/>
          <UserMenu {...{userMenuRef, toggleUserMenu, userMenuFlag}}/>
        </div>
      </React.Suspense>
      :
      <>
      <div className='mw768-display-none'>
        <div 
          className='display-flex'
          onClick={() => {setFlag(true)}}
        >
          <ArrowCircleBrokenRightSvg className='display-block height-1dot25rem width-1dot25rem color-orange-600'/>
          <div className='padding-left-0dot5rem text-sm medium color-orange-600'>
            <FormattedMessage defaultMessage='Sign in'/>
          </div>
        </div>
      </div>
      <div className='display-none mw768-display-block'>
        <div 
          className='cursor-pointer border-radius-0dot5rem border-1px-solid border-color-orange-600 padding-top-0dot625rem padding-bottom-0dot625rem padding-left-1rem padding-right-1rem'
          style={{
            boxShadow: '0px 1px 2px 0px #1018280D',
            filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
          }}
          onClick={() => {setFlag(true)}}
        >
          <div className='display-flex'>
            <ArrowCircleBrokenRightSvg className='display-block height-1dot25rem width-1dot25rem color-orange-600'/>
            <div className='padding-left-0dot5rem text-sm medium color-orange-600'>
              <FormattedMessage defaultMessage='Sign in'/>
            </div>
          </div>
        </div>
      </div>
      </>
    }
    </>
  );
});

export default React.memo(() => {
  const location = useLocation();
  const {locale = 'uk', serviceId} = useQuery();
  const [flag, setFlag] = React.useState();
  const drawerRef = React.useRef()
  React.useEffect(() => {
    const onClickOutsideHandler = (event) => {
        if (flag && !(
            drawerRef.current.contains(event.target)
        )) setFlag(false);
    };
    document.addEventListener('click', onClickOutsideHandler, true);
    return () => {
        document.removeEventListener('click', onClickOutsideHandler, true);
    };
  }, [drawerRef, flag, setFlag]);
  const supportContact = serviceId === demoServiceId ? SUPPORT.ottry : SUPPORT.default;
  return (
    <div className='display-flex justify-content-space-between align-items-center'>
      <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-top-1rem padding-bottom-1rem'>
        <Link to={`/${searchFilter(location.search)}`}>
          <GroupSvg className='display-block' style={{width: 134, height: 40}} />
          {/* <MarchSvg className='display-block' style={{width: 134, height: 40}} /> */}
          {/* <img className='display-block' style={{width: 134, height: 40}} src={FebruaryPng} alt=''/> */}
        </Link>
      </div>
      <div className='padding-right-1dot25rem mw768-padding-right-2rem'>
        <div className='display-flex align-items-center'>
          <div className='display-flex align-items-center'>
            <div className='padding-right-1rem'>
              <HelpCircleSvg 
                className='display-block cursor-pointer height-1dot25rem width-1dot25rem color-gray-500'
                onClick={() => setFlag(true)}
              />
              <div 
                className={classNames('drawer-backdrop', {
                  'show': flag
                })}
              >
                <div ref={drawerRef} className='position-fixed background-color-white display-flex flex-direction-column drawer'>
                  <div className='display-flex justify-content-flex-end padding-right-0dot75rem padding-top-0dot75rem'>
                    <div className='display-flex justify-content-center align-items-center width-2dot5rem height-2dot5rem'>
                      <Xsvg 
                        className='display-block cursor-pointer width-1dot25rem height-1dot25rem color-gray-500'
                        onClick={() => setFlag(false)}
                      />
                    </div>
                  </div>
                  <div className='padding-left-1dot5rem mw768-padding-left-3dot5rem padding-right-1dot5rem mw768-padding-right-3dot5rem flex-1'>
                    <div className='padding-top-1dot5rem text-md semibold color-gray-700'>
                      <FormattedMessage defaultMessage='Have you got any questions?'/>
                    </div>
                    <div className='padding-top-0dot25rem text-md color-gray-500'>
                      <FormattedMessage defaultMessage='Contact us!'/>
                    </div>
                    <div className='padding-top-1rem padding-bottom-1dot5rem'>
                      <a className='display-xs semibold color-orange-600' href={`tel:${supportContact.number}`}>
                        {supportContact.formatted}
                      </a>
                    </div>
                    <div className='border-top-1px-solid border-color-gray-200 padding-top-1dot5rem text-sm'>
                      <a className='color-gray-400 margin-right-1rem' href={legal + `/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a>
                      <a className='color-gray-400 margin-right-1rem' href={legal + `/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms'/></a>
                      <a className='color-gray-400' href={legal + `/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy'/></a>
                    </div>
                  </div>
                  <div className='padding-top-2rem padding-bottom-1dot5rem display-flex align-items-center padding-left-1dot5rem padding-right-1dot5rem'>
                    <div className='display-flex align-items-center flex-1'>
                      <p className='text-xs medium color-gray-500'>
                        <FormattedMessage defaultMessage='Powered by'/>
                      </p>
                      <LogoText className='height-0dot875rem margin-left-0dot5rem'/>
                    </div>
                    <div className=''>
                      <Locale/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div><Auth/></div>
          </div>
        </div>
      </div>
    </div>
  );
});
