import React from 'react';
import './home.scss';
import XCloseSvg from '../assets/icons0/x-close.svg';
import MinusSvg from '../assets/icons0/minus.svg';
import PlusSvg from '../assets/icons0/plus.svg';
import {graphql, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import useQuery from '../utilities/use-query';
import i18n from '../utilities/i18n';
import {useCartDispatch} from './cart';
import classNames from 'classnames';
import Browser from './browser';
import useCartStateEx from '../utilities/use-cart-state-ex';
import useClaimStateEx from '../utilities/use-claim-state-ex';
import useSubscription from '../utilities/use-subscription';
import useSubscriptionStatus from '../utilities/use-subscription-status';
import ImpulseUncompleted from './impulse-uncompleted';
import Amount from './amount';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackComponent from './fallback-component';
import {FormattedMessage} from 'react-intl';

const Count = React.memo(({endpoint, serviceById, setQuantity, close}) => {
  const fEndpoint = useFragment(
    graphql`
        fragment endpointCountComponents_endpoint on Endpoint {
            id
        }
    `,
    endpoint
  );
  const fServiceById = useFragment(
    graphql`
        fragment endpointCountComponents_serviceById on Service {
            id
        }
    `,
    serviceById
  );
  const cartState = useCartStateEx();
  const cartDispatch = useCartDispatch();
  const count = React.useMemo(() =>
    cartState
        .filter(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id)
        .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
    [cartState, fEndpoint.id, fServiceById.id]
  );
  return (
    <>
    {!!count && 
      <div className='display-flex align-items-center'>
        <div 
          className='display-flex align-items-center cursor-pointer'
          onClick={() => {
            cartDispatch({
              type: 'remove', 
              payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}
            });
            setQuantity(1);
            close();
        }}
        >
          <XCloseSvg className='display-block height-1dot25rem width-1dot25rem color-orange-600'/>
          <div className='padding-left-0dot5rem text-md medium color-orange-600'><FormattedMessage defaultMessage='Remove'/> {count}</div>
        </div>
      </div>
      }
    </>
  );
});

const EndpointAction = ({endpoint, serviceId, close, quantity, setQuantity}) => {
  const cartDispatch = useCartDispatch();
  const quantityDeferred = React.useDeferredValue(quantity);
  const localCartState = [{endpointId: endpoint.id, serviceId, quantity: quantityDeferred}];
  return (
    <div className='bar position-fixed mw768-position-static width-100percent bottom-0 padding-1dot25rem mw768-padding-left-0 mw768-padding-right-0 mw768-padding-bottom-0 mw768-padding-top-2rem border-top-left-radius-0dot75rem border-top-right-radius-0dot75rem mw768-border-top-left-radius-0 mw768-border-top-right-radius-0 background-color-white mw768-background-color-transparent display-flex justify-content-space-between'>
      <div className='display-flex align-items-stretch border-radius-0dot5rem border-1px-solid border-color-gray-200'>
        <div 
          className='cursor-pointer padding-left-0dot75rem display-flex align-items-center'
          onClick={() => setQuantity(e => e > 1 ? e - 1 : e)}
        >
          <MinusSvg className={classNames('display-block height-1dot25rem width-1dot25rem', {
            'color-gray-700': quantity > 1,
            'color-gray-200': quantity < 2
          })}/>
        </div>
        <div className='padding-left-1dot125rem padding-right-1dot125rem padding-top-0dot9375rem padding-bottom-0dot9375rem'>
          <div className='width-1dot5rem display-flex justify-content-center'>
            <div className='text-md color-gray-700 medium'>
              {quantity}
            </div>
          </div>
        </div>
        <div 
          className='cursor-pointer padding-right-0dot75rem display-flex align-items-center'
          onClick={() => setQuantity(e => e + 1)}
        >
          <PlusSvg className='cursor-pointer display-block height-1dot25rem width-1dot25rem color-gray-700'/>
        </div>
      </div>
      <div className='padding-left-0dot75rem flex-1'>
        <div
          style={{
            background: 'linear-gradient(88.92deg, #EC4A0A 0%, #FB6514 100%)',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
          }}
          className='cursor-pointer display-flex align-items-center justify-content-center border-radius-0dot5rem padding-1rem'
          onClick={() => {
            cartDispatch({
              type: 'add', 
              payload: {endpointId: endpoint.id, serviceId, quantity}
            });
            setQuantity(1);
            close();
          }}
        >
          <div className='text-md medium color-white'>
            <FormattedMessage defaultMessage='Add'/>
            {quantity === 1 ?
              <span className='padding-left-0dot5rem'>{endpoint.price} ₴</span>
              :
              <React.Suspense fallback={<span className='padding-left-0dot5rem opacity-0dot6'>{endpoint.price} ₴</span>}>
                <ErrorBoundary {...{FallbackComponent}}>
                  <Amount {...{cartState: localCartState, flag: quantity !== quantityDeferred}}/>
                </ErrorBoundary>
              </React.Suspense>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const EndpointFooterImpl = ({children, refreshedQueryOptions}) => {
  const claimState = useClaimStateEx();
  const {impulseByClaim} = useLazyLoadQuery(
    graphql`
      query endpointImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
          impulseByClaim(request: $claimRequest) {
              id
              status
              claim
          }
      }
    `,
    refreshedQueryOptions.variables,
    refreshedQueryOptions.options
  );
  const impulseUncompleted = !claimState[0].successPageVisited && impulseByClaim.status !== 4;
  return (
    <>
      {
        impulseUncompleted
        ?
        <div className='position-fixed mw768-position-static width-100percent bottom-0 padding-bottom-1dot25rem padding-right-1rem padding-left-1rem mw768-padding-left-0 mw768-padding-right-0 mw768-padding-bottom-0 mw768-padding-top-2rem'>
          <ImpulseUncompleted {...{impulseId: impulseByClaim.id, impulseExpired: [6].includes(impulseByClaim.status)}}/>
        </div>
        :
        <>{children}</>
      }
    </>
  )
}

const EndpointFooter = ({children}) => {
  const claimState = useClaimStateEx();
  const impulseId = claimState[0].id;
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
    options: {fetchKey: 0, fetchPolicy: 'network-only'},
    variables: {claimRequest: {value: claimState[0].claim, fetchKey: 0}}
  });
  const [isPending, startTransition] = React.useTransition();
  const refresh = React.useCallback(() => {
    startTransition(() => {
        setRefreshedQueryOptions(prev => ({
            ...prev, 
            options: {...prev.options, fetchKey: prev.options.fetchKey + 1},
            variables: {...prev.variables, claimRequest: {...prev.variables.claimRequest, fetchKey: prev.variables.claimRequest.fetchKey + 1}}
        }));
    });
  }, []);
  const payload = React.useMemo(() => ({
    channel: `/impulses/${impulseId}`,
    onNext: () => refresh(),
    onError: () => {}
  }), [refresh, impulseId]);
  useSubscription(payload);
  const subscriptionStatus = useSubscriptionStatus();
  React.useEffect(() => {
      if (subscriptionStatus === 0) refresh();
  }, [subscriptionStatus, refresh]);
  return (
    <EndpointFooterImpl {...{refreshedQueryOptions}}>
      <>{children}</>
    </EndpointFooterImpl>
  )
}

export default React.memo(({endpoint, serviceById, close}) => {
  const claimState = useClaimStateEx();
  const fEndpoint = useFragment(
    graphql`
        fragment endpointComponents_endpoint on Endpoint {
            id
            name
            nameUk
            details
            detailsUk
            quantity
            price
            ...endpointCountComponents_endpoint
        }
    `,
    endpoint
  );
  const fServiceById = useFragment(
    graphql`
        fragment endpointComponents_serviceById on Service {
            id
            ...endpointCountComponents_serviceById
        }
    `,
    serviceById
  );
  const {locale = 'uk'} = useQuery();
  const cover = React.useMemo(() => {
    if (i18n(fEndpoint, 'details', locale)) {
        const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
        const found = i18n(fEndpoint, 'details', locale).match(regex);
        return found && found[0];
    }
  }, [fEndpoint, locale]);
  const [quantity, setQuantity] = React.useState(1);
  return (
    <>
    <div className='padding-1dot25rem mw768-padding-top-1dot5rem mw768-padding-left-1dot5rem mw768-padding-right-1dot5rem mw768-padding-bottom-0'>
      <div className='display-flex justify-content-flex-end'>
        <XCloseSvg 
          className='cursor-pointer display-block height-1dot5rem width-1dot5rem color-gray-500'
          onClick={() => close()}
        />
      </div>
      <div className='display-sm semibold color-gray-900'>
        {i18n(fEndpoint, 'name', locale)}
      </div>
      <div className='padding-top-0dot5rem text-md color-gray-500'>
        {/* {i18n(fEndpoint, 'details', locale)} */}
      </div>
      <div className='padding-top-1dot5rem padding-bottom-1dot5rem'>
        <div className='display-flex justify-content-center'>
          {cover && <img
            className='display-block max-width-100percent max-height-100percent border-radius-0dot25rem mw768-border-radius-0dot5rem'
            src={cover}
          />}
        </div>
      </div>
      <Browser>
        <Count {...{endpoint: fEndpoint, serviceById: fServiceById, setQuantity, close}}/>
      </Browser>
    </div>
      <div className='mw768-padding-bottom-1dot5rem mw768-padding-left-1dot5rem mw768-padding-right-1dot5rem'>
        {!claimState.length  ?
          <EndpointAction {...{endpoint: fEndpoint, serviceId: fServiceById.id, close, quantity, setQuantity}}/>
          :
          <React.Suspense>
            <EndpointFooter>
              <EndpointAction {...{endpoint: fEndpoint, serviceId: fServiceById.id, close, quantity, setQuantity}}/>
            </EndpointFooter>
          </React.Suspense>
        }
      </div>
    </>
  );
});

