import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import moment from 'moment';
import {useLazyLoadQuery} from "react-relay/hooks";
import {useBuffetDispatch} from "./buffet";
import PopUp from './pop-up/pop-up';
import IconFeature from "./icon-feature/icon-feature";
import LockUnlocked from '../assets/icons0/lock-unlocked-01.svg';
import Phone from '../assets/icons0/phone.svg';
import Lock from '../assets/icons0/lock-01.svg';
import i18n from '../utilities/i18n';
import useQuery from "../utilities/use-query";
import searchFilter from "../utilities/search-filter";
import useNow from "../utilities/use-now";
import './pop-up-impulse.scss';
import Skeleton from "./skeleton/skeleton";
import {ErrorBoundary} from "react-error-boundary";
import FallbackComponent from "./fallback-component";
import useBuffetStateEx from "../utilities/use-buffet-state-ex";
import {FormattedMessage} from 'react-intl';

const mmAutocloseTimeout = 90;
const demoServiceId = process.env.RAZZLE_APP_DEMO_SERVICE_ID;
const SUPPORT = {
  ottry: {
    formatted: '+38 (097) 938-11-62',
    number: '+380979381162'
  },
  default : {
    formatted: '0-800-33-56-89',
    number: '0800335689'
  }
}

const mmStates = {
    opened: {
        title: <FormattedMessage defaultMessage='The micromarket is open'/>,
        icon: <LockUnlocked/>,
        iconColor: 'success'
    },
    closed: {
        title: <FormattedMessage defaultMessage='The micromarket is closed'/>,
        icon: <Lock/>,
        iconColor: 'error'
    }
}

const LoadingBar = ({timer}) => {
    const [animationDuration, setAnimationDuration] = React.useState();
    React.useEffect(() => {
        setAnimationDuration(timer);
    }, [])
    return (
       <div className='progress'>
            <svg viewBox="0 0 48 48"> 
                <g fill="none" strokeWidth="6"> 
                    <path d="M3,24a21,21 0 1,0 42,0a21,21 0 1,0 -42,0" stroke="#F8F9FC" strokeLinejoin="round" /> 
                </g>
            </svg>
            <svg viewBox="0 0 48 48"> 
                <circle r="21" cx="24" cy="24" style={{animation: `load ${animationDuration}s linear`}}/> 
            </svg>
            <div className='progress-time text-lg color-gray-500'>{timer}</div>
       </div>
    )
}

const PopUpContent = ({now, buffetImpulse}) => {
    const {locale = 'uk', serviceId} = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const buffetDispatch = useBuffetDispatch();
    const [isPending, startTransition] = React.useTransition();
    const mmOpenedfromNow = moment(now).diff(moment(buffetImpulse.opened), 'seconds');
    const mmState = mmOpenedfromNow >= mmAutocloseTimeout ? 'closed' : 'opened';
    const {impulseByClaim} = useLazyLoadQuery(
        graphql`
          query popUpImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
                impulseByClaim(request: $claimRequest) {
                    id
                    claim
                    impulseService {
                        service {
                            id
                            version
                        }
                    }
                    impulseEndpoints {
                        endpoint {
                            name
                            nameUk
                        }
                        quantity
                    }
                    status
                }
            }
        `,
        {claimRequest: {value: buffetImpulse.claim}}
    );
    const closeBuffet = () => {
        startTransition(() => {
            buffetDispatch({type: 'remove', payload: {id: impulseByClaim.id}});
            if (location.pathname !== '/') {
                navigate(`/${searchFilter(location.search)}`);
            }
        })
    };
    const supportContact = serviceId === demoServiceId ? SUPPORT.ottry : SUPPORT.default;
    return (
        <div className='pop-up-impulse padding-top-1dot25rem padding-left-1rem padding-right-1rem padding-bottom-1rem mw768-padding-1dot5rem'>
            <div className='display-flex padding-bottom-1rem border-bottom-1px-solid border-color-gray-200 align-items-center'>
                <IconFeature {...{size: 'md', feature: 'outline', color: mmStates[mmState].iconColor}}>
                    {mmStates[mmState].icon}
                </IconFeature>
                <div className='padding-left-1rem'>
                    <p className='text-md medium color-gray-700'>{mmStates[mmState].title}</p>
                    <p className='text-sm color-gray-500'><FormattedMessage defaultMessage='Closes automatically'/></p>
                </div>
                <div className='flex-1 text-align-right'>
                    <div className='position-relative height-3rem'>
                        <div className='position-absolute top-0 right-0'>
                            <LoadingBar timer={mmState === 'closed' ? 0 : mmAutocloseTimeout - mmOpenedfromNow}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='padding-top-1rem'>
                <p className='text-xl medium color-gray-900 padding-bottom-0dot75rem'><FormattedMessage defaultMessage='Your order'/></p>
                <div className='border-1px-solid border-color-gray-200 background-color-gray-50 border-radius-0dot5rem padding-1rem'>
                    {impulseByClaim.impulseEndpoints.map((item, index) =>
                        <div key={index} className='display-flex mm-item'>
                            <p className='flex-1 text-md medium color-gray-600'>{i18n(item.endpoint, 'name', locale)}</p>
                            <p className='padding-left-0dot75rem text-md semibold color-orange-600'>x{item.quantity}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className='padding-top-1dot5rem mw768-padding-top-2rem'>
                <div
                    className='border-1px-solid border-color-gray-300 border-radius-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot25rem padding-right-1dot25rem cursor-pointer'
                    style={{
                        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        background: 'linear-gradient(45deg, #1D2939 0%, #475467 100%)'
                    }}
                    onClick={() => closeBuffet()}
                >
                    <div className='text-md medium color-white text-align-center'>
                        <FormattedMessage defaultMessage='Close'/>
                    </div>
                </div>
                <a
                    className='padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot25rem padding-right-1dot25rem display-block color-gray-500 display-flex align-items-center justify-content-center margin-top-0dot75rem'
                    href={`tel:${supportContact.number}`}
                >
                    <Phone className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                    <div className='text-md medium'>
                        {supportContact.formatted}
                    </div>
                </a>
            </div>
        </div>
    )
}

const PopUpImpulse = () => {
    const buffetState = useBuffetStateEx();
    const [buffetImpulse, setBuffetImpulse] = React.useState(false);
    const now = useNow();
    const nowDeferred = React.useDeferredValue(now);
    React.useEffect(() => {
        setBuffetImpulse(buffetState[0]);
    }, [buffetState]);
    return (
        <PopUp {...{isVisible: !!buffetImpulse}}>
            {buffetImpulse && 
                <React.Suspense fallback={<div className='padding-top-1dot25rem padding-left-1rem padding-right-1rem padding-bottom-1rem mw768-padding-1dot5rem'><Skeleton/></div>}>
                    <ErrorBoundary {...{FallbackComponent}}>
                        <PopUpContent {...{now: nowDeferred, buffetImpulse}}/>
                    </ErrorBoundary>
                </React.Suspense>
            }
        </PopUp>
    )
}

export default React.memo(PopUpImpulse);
