import React from 'react';
import './complete.scss';
import GreenSvg from '../assets/green.svg';
import OrangeSvg from '../assets/orange.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import searchFilter from '../utilities/search-filter';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './skeleton/skeleton';
import Browser from './browser';
import FallbackComponent from './fallback-component';
import {Helmet} from 'react-helmet-async';
import {useClaimDispatch} from './claim';
import useQuery from '../utilities/use-query';
import useSubscriptionStatus from '../utilities/use-subscription-status';
import useSubscription from '../utilities/use-subscription';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import classNames from 'classnames';
import useClaimStateEx from '../utilities/use-claim-state-ex';
import {useBuffetDispatch} from './buffet';
import PopUpImpulse from './pop-up-impulse';
import useBuffetStateEx from '../utilities/use-buffet-state-ex';
import CheckServiceState from './check-service-state';
import {FormattedMessage} from 'react-intl';

const CompleteImpl = React.memo(({refreshedQueryOptions, flag}) => {
  const claimDispatch = useClaimDispatch();
  const buffetDispatch = useBuffetDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {impulseByClaim} = useLazyLoadQuery(
    graphql`
      query completeCompleteImplImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
          impulseByClaim(request: $claimRequest) {
              id
              claim
              impulseService {
                  service {
                      id
                      version
                  }
              }
              status
          }
      }
    `,
    refreshedQueryOptions.variables,
    refreshedQueryOptions.options
  );
  const [apply, applying] = useMutation(
    graphql`
        mutation completeCompleteImplWebhookServiceQuery($request: WebhookServiceRequestInput) {
            webhookService(request: $request) {
                id
            }
        }
  `);
  const submit = () => {
    apply({
        variables: {
            request: {
                id: impulseByClaim.impulseService.service.id,
                version: impulseByClaim.impulseService.service.version,
                event: 'service.open'
            }
        },
        onCompleted: () => {
          buffetDispatch({type: 'add', payload: {id: impulseByClaim.id, serviceId: impulseByClaim.impulseService.service.id, claim: impulseByClaim.claim, opened: new Date()}});
          claimDispatch({type: 'remove', payload: {id: impulseByClaim.id}});
        },
        onError: () => {}
    });
  };
  return (
    <div className='complete active'>
      <div className={classNames({'opacity-0dot6': flag})}>
        <div className='mw768-max-width-80rem mw768-margin-0-auto'>
          <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem'>
            <div className='padding-top-4dot5rem mw768-padding-top-6rem'>
              {impulseByClaim.status === 4 ?
                <>
                <div className='display-flex justify-content-center'>
                  <GreenSvg
                    style={{
                      with: 250,
                      height: 200
                    }}
                    className='display-block'
                  />
                </div>
                <div className='mw768-padding-top-1dot5rem text-align-center display-xs semibold color-gray-900'>
                  <FormattedMessage defaultMessage='Thank you for your purchase'/>
                </div>
                <div className='mw768-max-width-37rem mw768-margin-0-auto'>
                  <div className='padding-top-0dot5rem mw768-padding-top-0dot75rem text-align-center text-md color-gray-500'>
                    <FormattedMessage defaultMessage='Now you can open the micromarket and pick up your purchase. Please only take the products you have purchased.'/>
                  </div>
                </div>
                </>
                :
                <>
                <div className='display-flex justify-content-center'>
                  <OrangeSvg
                    style={{
                      with: 250,
                      height: 200
                    }}
                    className='display-block'
                  />
                </div>
                <div className='mw768-padding-top-1dot5rem text-align-center display-xs semibold color-gray-900'>
                  <FormattedMessage defaultMessage='Payment in processing'/>
                </div>
                <div className='mw768-max-width-37rem mw768-margin-0-auto'>
                  <div className='padding-top-0dot5rem mw768-padding-top-0dot75rem text-align-center text-md color-gray-500'>
                    <FormattedMessage defaultMessage='Your payment is processed by the bank. You will be able to open a micromarket as soon as the payment is made.'/>
                  </div>
                </div>
                </>
              }
              <div className='mw768-max-width-37rem mw768-margin-0-auto'>
                <div className='display-none mw768-display-block'>
                  <div className='padding-top-2rem display-flex justify-content-center'>
                    <Link to={`/${searchFilter(location.search)}`}>
                      <div 
                        style={{
                          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                        }}
                        className='margin-right-0dot375rem border-1px-solid border-color-gray-300 border-radius-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot25rem padding-right-1dot25rem display-flex justify-content-center'
                      >
                        <div className='text-md medium color-gray-700'>
                          <FormattedMessage defaultMessage='Open later'/>
                        </div>
                      </div>
                    </Link>
                    {impulseByClaim.status === 4 ?
                      <div
                        style={{
                          background: 'linear-gradient(88.92deg, #039855 0%, #12B76A 100%)',
                          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                        }}
                        className='cursor-pointer margin-left-0dot375rem border-radius-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot25rem padding-right-1dot25rem display-flex justify-content-center'
                        onClick={() => impulseByClaim.status === 4 && !applying && submit()}
                      >
                        <div className='text-md medium color-white'>
                          <FormattedMessage defaultMessage='Open micromarket'/>{applying && '...'}
                        </div>
                      </div>
                      :
                      <div
                        style={{
                          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                        }}
                        className='cursor-pointer margin-left-0dot375rem border-radius-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot25rem padding-right-1dot25rem display-flex justify-content-center background-color-gray-200'
                      >
                        <div className='text-md medium color-white display-flex justify-content-center'>
                          <FormattedMessage defaultMessage='Open micromarket'/>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div className='mw768-display-none'>
            <div className='position-fixed width-100percent bottom-7rem'>
              <div className='display-flex justify-content-center'>
                <Link to={`/${searchFilter(location.search)}`}>
                  <div className='text-md medium color-success-600'>
                    <FormattedMessage defaultMessage='Open later'/>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='foo bar position-fixed width-100percent bottom-0 padding-1dot25rem background-color-white'>
            {impulseByClaim.status === 4 ?
              <div
                style={{
                  background: 'linear-gradient(88.92deg, #039855 0%, #12B76A 100%)',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                }}
                className='border-radius-0dot5rem padding-1rem cursor-pointer'
                onClick={() => impulseByClaim.status === 4 && !applying && submit()}
              >
                <div className='text-md medium color-white display-flex justify-content-center'>
                  <FormattedMessage defaultMessage='Open micromarket'/>{applying && '...'}
                </div>
              </div>
              :
              <div
                style={{
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                }}
                className='border-radius-0dot5rem padding-1rem background-color-gray-200'
              >
                <div className='text-md medium color-white display-flex justify-content-center'>
                  <FormattedMessage defaultMessage='Open micromarket'/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

const Complete = React.memo(({impulseId, value}) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
    options: {fetchKey: 0, fetchPolicy: 'network-only'},
    variables: {claimRequest: {value: value.claim, fetchKey: 0}}
  });
  const [isPending, startTransition] = React.useTransition();
  const refresh = React.useCallback(() => {
    startTransition(() => {
        setRefreshedQueryOptions(prev => ({
            ...prev, 
            options: {...prev.options, fetchKey: prev.options.fetchKey + 1},
            variables: {...prev.variables, claimRequest: {...prev.variables.claimRequest, fetchKey: prev.variables.claimRequest.fetchKey + 1}}
        }));
    });
  }, []);
  const payload = React.useMemo(() => ({
    channel: `/impulses/${impulseId}`,
    onNext: () => refresh(),
    onError: () => {}
  }), [refresh, impulseId]);
  useSubscription(payload);
  const subscriptionStatus = useSubscriptionStatus();
  React.useEffect(() => {
      if (subscriptionStatus === 0) refresh();
  }, [subscriptionStatus, refresh]);
  return (
    <>
    {value ? 
      <CompleteImpl {...{
        refreshedQueryOptions,
        flag: isPending
      }}/>
      :
      <>Your complete is empty</>
    }
    </>
  );
});

const Value = React.memo(() => {
  const {impulseId} = useQuery();
  const claimDispatch = useClaimDispatch();
  const claimState = useClaimStateEx();
  const buffetState = useBuffetStateEx();
  const value = React.useMemo(() => claimState.find(e => e.id === impulseId), [claimState, impulseId]);
  React.useEffect(() => {
    if (value && !value.successPageVisited) {
      claimDispatch({type: 'update', payload: {id: value.id, successPageVisited: true}});
    }
  }, [value]);
  const buffetValue = React.useMemo(() => buffetState.find(e => e.id === impulseId), [buffetState, impulseId]);
  return (
    <>
    {value
      ?
      <Complete {...{impulseId, value}}/>
      :
      buffetValue
      ?
      <Complete {...{impulseId, value: buffetValue}}/>
      :
      <>Your complete is empty</>
    }
    </>
  );
});

export default React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Time to eat complete</title>
        <meta name='description' content='Web site of your dream'/>
    </Helmet>
    <Browser>
      <React.Suspense fallback={
        <div className='mw768-max-width-80rem mw768-margin-0-auto'>
          <div className='padding-left-1dot25rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem'>
            <div className='padding-top-4dot5rem mw768-padding-top-6rem'>
              <Skeleton/>
            </div>
          </div>
        </div>
      }>
        <ErrorBoundary {...{FallbackComponent}}>
          <Value/>
          <CheckServiceState/>
          <PopUpImpulse/>
        </ErrorBoundary>
      </React.Suspense>
    </Browser>
    </>
  );
});
